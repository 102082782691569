import { lazy, Suspense } from "react";
import { createBrowserRouter, Outlet, useRouteError } from "react-router-dom";
import { NotFoundErrorPage } from "./ErrorPages";
import OrderSearch from "./features/tracking/OrderSearch";

const ShipmentTracking = lazy(
  () => import("./features/tracking/ShipmentTracking"),
);
const OrderDetails = lazy(() => import("./features/tracking/OrderDetails"));
const NetworkOrderSearch = lazy(
  () => import("./features/tracking/NetworkOrderSearch"),
);

const ErrorElement = function ErrorElement() {
  throw useRouteError();
};

export const routes = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorElement />,
    element: (
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: "orders/:orderId",
        element: (
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        ),
        children: [
          {
            path: "tracking/:shipmentId",
            element: <ShipmentTracking />,
          },
          {
            path: "file-claim?",
            element: <OrderDetails />,
          },
        ],
      },
      {
        path: "/orders",
        element: <OrderSearch />,
      },
      {
        path: "/:networkId/orders",
        element: <NetworkOrderSearch />,
      },
      {
        path: "*",
        element: <NotFoundErrorPage />,
      },
    ],
  },
]);
